import { Box, Typography } from '@mui/material';
import List from './List';
import classes from './index.module.scss';
import { useQuery } from '@tanstack/react-query';
import { apiCms } from '../../../../../instances/axiosInstances';
import { getRelatedPostsQuery } from './query';

export default function RelatedPosts({ tags, postDetailsSlug }) {
  const { data } = useQuery(
    ['relatedPosts', tags],
    async () => {
      const query = await getRelatedPostsQuery({ tags, postDetailsSlug });
      if (!query) return [];

      const { data } = await apiCms.get('/blogs', { params: query });
      return data;
    },
    {
      enabled: !!tags && tags.length > 0,
    }
  );

  const postsList = data?.data;

  if (!postsList || !postsList.length) return null;

  return (
    <Box className={classes.wrapper}>
      <Typography component={'h3'} variant={'h3'} className={classes.title}>
        Related articles
      </Typography>
      <List posts={postsList} />
    </Box>
  );
}
