import { Input } from "../../MUI/inputs";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./schema";
import { AccentButton } from "../../MUI/buttons/button";
import { api } from "../../../instances/axiosInstances";
import Box from "@mui/material/Box";

import classes from "./index.module.scss";
import { useEffect } from "react";

export default function CouponForm({
  subscriptionPlanId,
  setClientSecret,
  setStripeSubscriptionId,
  stripeSubscriptionId,
  setIsPaymentProviderOpen,
  setIsLoadingPromo,
  setPromotingPrice,
  promotingPrice,
  isUpgrade,
}) {
  const {
    trigger,
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { mutate, isLoading, error } = useMutation(
    (data) => {
      setPromotingPrice(undefined);
      setIsLoadingPromo(true);
      setIsPaymentProviderOpen(false);
      return api.post(
        isUpgrade
          ? "/subscriptions/stripe/promotion-code/upgrade"
          : "/subscriptions/stripe/promotion-code",
        data
      );
    },
    {
      onSuccess: async ({ data }) => {
        setClientSecret(data.clientSecret);
        setStripeSubscriptionId(data.stripeSubscriptionId);
        setPromotingPrice(data.newPrice);
        setIsPaymentProviderOpen(true);
        setIsLoadingPromo(false);
      },
      onError: () => {
        setIsPaymentProviderOpen(true);
        setIsLoadingPromo(false);
      },
    }
  );

  const onSubmit = (data) => {
    const newData = isUpgrade
      ? { ...data, subscriptionPlanId }
      : { ...data, subscriptionPlanId, stripeSubscriptionId };
    mutate(newData);
  };

  useEffect(() => {
    !promotingPrice && typeof promotingPrice !== "number" && setValue("promoCode", "");
    !promotingPrice && typeof promotingPrice !== "number" && trigger(["promoCode"]);
  }, [promotingPrice]);

  return (
    <Box
      component={"form"}
      className={classes.form}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Input
        label="Coupon"
        type="text"
        margin="normal"
        variant="outlined"
        fullWidth
        autoComplete="new-password"
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
        }}
        placeholder="Code"
        {...register("promoCode", {
          onChange: () => trigger(["promoCode"]),
        })}
        error={!!errors.promoCode || !!error}
        helperText={
          errors.promoCode
            ? errors.promoCode.message
            : error
            ? error.response.data.message
            : null
        }
        defaultValue={"TESTMONTHLY"}
        disabled={promotingPrice}
      />
      <AccentButton
        isBig={false}
        type="submit"
        variant="contained"
        disabled={promotingPrice || !getValues("promoCode") || isLoading}
      >
        Apply
      </AccentButton>
    </Box>
  );
}
