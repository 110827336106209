import { Box } from "@mui/material";
import PostCard from "../../../../../components/MUI/cards/PostCard";
import classes from "./index.module.scss";
import { useDeferredValue } from "react";

export default function List({ posts }) {
  const deferredPosts = useDeferredValue(posts || []);
  if (!deferredPosts || !deferredPosts.length) return null;

  return (
    <Box component={"ul"} className={classes.list}>
      {deferredPosts.map((item) => (
        <Box key={item.id} component={"li"} className={classes.list__item}>
          <PostCard data={item} variant="post" link={`/posts/${item.slug}`} />
        </Box>
      ))}
    </Box>
  );
}
