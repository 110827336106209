import MarkdownIt from 'markdown-it';
import Shiki from '@shikijs/markdown-it';

export async function parseMarkdownWithToc({ markdown, codeTheme }) {
  const md = MarkdownIt({
    html: true,
  }).use(
    await Shiki({
      themes: {
        light: codeTheme || 'github-dark',
        dark: codeTheme || 'github-dark',
      },
      langs: [
        'javascript',
        'typescript',
        'css',
        'html',
        'swift',
        'java',
        'kotlin',
      ],
    })
  );

  // Parse the markdown to extract tokens (without adding anchor IDs yet)
  const tokens = md.parse(markdown, {});

  const headers = tokens
    .filter((token) => token.type === 'heading_open')
    .map((token) => {
      const level = parseInt(token.tag.replace('h', ''), 10);
      const text = tokens[tokens.indexOf(token) + 1].content;

      // Clean the text to generate a valid ID only for h2
      let id = '';
      if (level === 2) {
        // Ensure the ID is clean and consistent with the title text
        id = text
          .toLowerCase()
          .replace(/[’‘]/g, "'") // Replace curly apostrophes with regular ones
          .replace(/[^a-z0-9\s-]/g, '') // Remove any non-alphanumeric characters (including punctuation)
          .replace(/\s+/g, '-') // Replace spaces with hyphens
          .replace(/^-+|-+$/g, ''); // Remove leading/trailing hyphens
      }

      return { level, text, id };
    });

  // Render the markdown content into HTML (without the anchor IDs)
  let renderedMarkdown = md.render(markdown);

  // Manually add the anchor IDs to the h2 headings in the rendered markdown
  headers.forEach((header) => {
    if (header.level === 2 && header.id) {
      // Create a regular expression to match the exact h2 content
      const regex = new RegExp(`<h2>(.*?)</h2>`, 'g');

      // Use the header text to ensure that we match the right h2 content and replace it with the correct id
      renderedMarkdown = renderedMarkdown.replace(regex, (match, content) => {
        // Ensure the content inside the h2 tag matches exactly and inject the correct ID
        if (content.trim() === header.text.trim()) {
          return match.replace(
            content,
            `<h2 id="${header.id}">${content}</h2>`
          );
        }
        return match;
      });
    }
  });

  return { renderedMarkdown, headers };
}
