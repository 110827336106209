import { Box, Container, Typography } from "@mui/material";
import { PlatformLayout } from "../../../components/Layouts";
import { NavLink, Outlet } from "react-router-dom";
import classes from "./index.module.scss";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";

export default function Courses() {
  const { auth } = useContext(AuthContext);

  return (
    <PlatformLayout>
      <Container disableGutters maxWidth={false} className={classes.container}>
        <div className={classes.wrapper}>
          <h1 className={classes.title}>Courses</h1>
          {auth ? (
            <Box className={classes.navigation}>
              <NavLink
                to={"/courses"}
                className={classes.link}
                end
              >
                All Courses
              </NavLink>

              <NavLink
                to={"my-courses"}
                className={classes.link}
                end
              >
                My Courses
              </NavLink>
            </Box>
          ) : null}

          <Outlet />
        </div>
      </Container>
    </PlatformLayout>
  );
}
