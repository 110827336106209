import Heading from "../../Heading/Heading";
import classes from "./PostWebinar.module.scss";

export default function PostWebinar(props) {
  if (!props || !props.id) return null;

  const { title, items, image, annotation } = props || {};

  return (
    <section className={classes.section}>
      <div className={classes.container}>
        <div className={classes.info}>
          <Heading className={classes.heading}>{title}</Heading>
          <ul className={classes.list}>
            {items.map((item, index) => (
              <li key={index} className={classes.list__item}>
                {item.title ? (
                  <h3 className={classes.title}>{item.title}</h3>
                ) : null}
                {item.text ? <p className={classes.text}>{item.text}</p> : null}
              </li>
            ))}
          </ul>
          {annotation ? (
            <p className={classes.annotation}>{annotation}</p>
          ) : null}
        </div>
        <div className={classes.imageBox}>
          <img src={`${process.env.REACT_APP_CMS_DOMAIN}${image.url}`} alt="" />
        </div>
      </div>
    </section>
  );
}
