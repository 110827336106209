import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import classes from "./index.module.scss";

export default function SubscribeForm() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.type = "text/javascript";
    script.charset = "utf-8";
    script.async = true;

    script.onload = () => {
      const hbspt = window.hbspt;
      if (hbspt) {
        hbspt.forms.create({
          portalId: "48295356",
          formId: "1a8883a9-d9de-40eb-a76b-a947b2aee83e",
          target: "#hubspot-form",
          css: "",
          onFormSubmitError: (event) => {
            console.error(event);
          },
        });
      }
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <Box className={classes.card}>
      <Typography
        component={"h3"}
        variant={"h3"}
        className={classes.card__title}
      >
        ✉️ Subscribe to get our latest news
      </Typography>
        <div id="hubspot-form" className={classes.form}></div>
    </Box>
  );
}
