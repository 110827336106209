import React from "react";
import { AccentButton, WhiteButton } from "../../MUI/buttons/button";
import promo from "./icons/promo.png";
import { useCountdown } from "../../../hooks/useCountDown";
import classes from "./index.module.scss";

export default function Popup2({
  cancelSubscriptionApi,
  setPopupKey,
  promoCodeData,
  redeemApi,
}) {
  const targetTimestamp = promoCodeData.expiresAt;
  const { timeLeft } = useCountdown(targetTimestamp);

  const isLessThenTen = (number) => {
    if (!number) return "00";
    return number < 10 ? `0${number}` : number;
  };

  const hours = isLessThenTen(timeLeft.hours);
  const minutes = isLessThenTen(timeLeft.minutes);
  const seconds = isLessThenTen(timeLeft.seconds);

  if (timeLeft.expired) {
    setPopupKey("popup1");
  }

  return (
    <div className={classes.content}>
      <div className={classes.text}>
        <h3 className={classes.title}>We don’t want to lose you!</h3>
        <p className={classes.description}>
          Here’s a <span className={classes.underline}>20% off</span> promo code
          for the next month. Code valid for{" "}
          <span className={classes.timer}>
            {hours}:{minutes}:{seconds}
          </span>
        </p>
      </div>
      <div className={classes.image}>
        <img src={promo} alt="Promo code" width={408} height={203} />
      </div>
      <div className={classes.actions}>
        <AccentButton
          disabled={cancelSubscriptionApi.isLoading || redeemApi.isLoading}
          onClick={() => redeemApi.mutate({ codeId: promoCodeData.codeId })}
          isBig={false}
          variant={"contained"}
          fullWidth
        >
          Redeem Promo Code
        </AccentButton>
        <WhiteButton
          onClick={cancelSubscriptionApi.mutate}
          disabled={cancelSubscriptionApi.isLoading || redeemApi.isLoading}
          isBig={false}
          variant={"contained"}
          fullWidth
        >
          Unsubscribe anyway
        </WhiteButton>
      </div>
    </div>
  );
}
