import React, { useEffect } from "react";
import classes from "./index.module.scss";

export default function Popup3() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.type = "text/javascript";
    script.charset = "utf-8";
    script.async = true;

    script.onload = () => {
      const hbspt = window.hbspt;
      if (hbspt) {
        hbspt.forms.create({
          portalId: "48295356",
          formId: "6a1f66a2-cfcb-4c19-9b9b-816bb91d0a2e",
          target: "#hubspot-form",
          css: "",
          onFormSubmitError: (event) => {
            console.error(event);
          },
        });
      }
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div className={classes.content}>
      <div id="hubspot-form" className={classes.form}></div>
    </div>
  );
}
