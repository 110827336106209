import CtaButton from "../../CtaButton/CtaButton";
import Heading from "../../Heading/Heading";
import classes from "./ForWho.module.scss";

export default function ForWho(props) {
  if (!props || !props.id) return null;

  const { title, subTitle, list, image, annotation, link } = props || {};

  return (
    <section className={classes.section}>
      <div className={classes.container}>
        <div className={classes.info}>
          <Heading>{title}</Heading>
          {subTitle ? <p className={classes.subHeading}>{subTitle}</p> : null}
          <ul className={classes.list}>
            {list && list.length > 0
              ? list.map((item, index) => (
                  <li key={index} className={classes.item}>
                    {item.title ? (
                      <h3 className={classes.item__title}>{item.title}</h3>
                    ) : null}
                    {item.text ? (
                      <p className={classes.item__text}>{item.text}</p>
                    ) : null}
                  </li>
                ))
              : null}
          </ul>
          {annotation ? (
            <p className={classes.annotation}>{annotation}</p>
          ) : null}
        </div>
        <div className={classes.imageBox}>
          <img
            src={`${process.env.REACT_APP_CMS_DOMAIN}${image.url}`}
            alt={title}
          />
        </div>
      </div>
      {link && link?.link ? (
        <div className={classes.actions}>
          <CtaButton href={link.link}>{link.text}</CtaButton>
        </div>
      ) : null}
    </section>
  );
}
