import { useContext, useState } from "react";
import Popup1 from "./Popup1";
import Popup2 from "./Popup2";
import Popup3 from "./Popup3";
import { ReactComponent as CloseIcon } from "./icons/close.svg";
import errorToast from "../../toasts/errorToast";
import successToast from "../../toasts/successToast";
import { useCancelSubscriptionApi } from "../../../apis/payments";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "../../../instances/axiosInstances";
import classes from "./index.module.scss";
import { SubscriptionsContext } from "../../../context/SubscriptionsContext";

const popups = {
  popup1: Popup1,
  popup2: Popup2,
  popup3: Popup3,
};

export default function UnsubscribePopup({ open, handleClose: closePopup }) {
  const [popupKey, setPopupKey] = useState("popup1");
  const [promoCodeData, setPromoCodeData] = useState(null);
  const {
    currentSubscription, 
  } = useContext(SubscriptionsContext);
  const Component = popups[popupKey];

  const queryClient = useQueryClient();
  const successToastId = "success-toast";
  const errorToastId = "error-toast";

  const handleClose = () => {
    setPopupKey("popup1");
    closePopup();
  };

  const onBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const cancelSubscriptionApi = useCancelSubscriptionApi({
    onSuccess: async () => {
      setPopupKey("popup3");
      await queryClient.invalidateQueries({
        queryKey: ["plans"],
        refetchType: "all",
      });
      await queryClient.invalidateQueries({
        queryKey: ["currentSubscription"],
        refetchType: "all",
      });
      successToast(
        "Subscription canceled. Plan active until next billing period",
        {
          toastId: successToastId,
        }
      );
    },
    onError: (error) => {
      errorToast(error?.response?.data?.message, {
        toastId: errorToastId,
        autoClose: false,
        hideProgressBar: true,
      });
    },
  });
  const unsubscribeApi = useMutation(
    () => api.post("/subscriptions/stripe/promotion-code/unsubscribe"),
    {
      onSuccess: async ({ data }) => {
        if (data.codeId && !currentSubscription?.downgradePlanId) {
          setPromoCodeData(data);
          setPopupKey("popup2");
        } else {
          cancelSubscriptionApi.mutate();
        }
      },
      onError: (error) => {
        errorToast(error?.response?.data?.message, {
          toastId: errorToastId,
          autoClose: false,
          hideProgressBar: true,
        });
      },
      staleTime: 0,
    }
  );
  const redeemApi = useMutation(
    (data) =>
      api.post(
        "/subscriptions/stripe/promotion-code/existing-subscription",
        data
      ),
    {
      onSuccess: async () => {
        handleClose();
        successToast(
          "Success! Promocode will be applied starting next billing period.",
          {
            toastId: successToastId,
          }
        );
      },
      onError: (error) => {
        errorToast(error?.response?.data?.message, {
          toastId: errorToastId,
          autoClose: false,
          hideProgressBar: true,
        });
      },
      staleTime: 0,
    }
  );

  if (!open) {
    return null;
  }

  return (
    <div className={classes.backdrop} onClick={onBackdropClick}>
      <div className={classes.modal}>
        <button onClick={handleClose} type="button" className={classes.close}>
          <CloseIcon />
        </button>
        <div className={classes.body}>
          <Component
            handleClose={handleClose}
            cancelSubscriptionApi={cancelSubscriptionApi}
            unsubscribeApi={unsubscribeApi}
            redeemApi={redeemApi}
            setPopupKey={setPopupKey}
            promoCodeData={promoCodeData}
          />
        </div>
      </div>
    </div>
  );
}
