import { Box, Typography } from '@mui/material';
import classes from './index.module.scss';

export default function Anchors({ toc }) {
  const handleScrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const tocList = toc.filter((item) => item.level === 2);

  return (
    <Box className={classes.wrapper}>
      <Typography component={'h3'} variant={'h3'} className={classes.title}>
        Contents
      </Typography>
      <ul className={classes.list}>
        {tocList.map((item) => (
          <li key={item.id}>
            <a
              href={`#${item.id}`}
              onClick={(event) => {
                event.preventDefault();
                handleScrollToSection(item.id);
              }}
            >
              {item.text}
            </a>
          </li>
        ))}
      </ul>
    </Box>
  );
}
