import Timer from "../../Timer/Timer";
import classes from "./Hero.module.scss";
import CtaButton from "../../CtaButton/CtaButton";

export default function Hero(props) {
  if (!props || !props.id) return null;

  const { badge, title, subTitle, date, link, bottomText, image, schedule } =
    props || {};

  return (
    <section className={classes.section}>
      <div className={classes.container}>
        <div className={classes.info}>
          <div className={classes.badge}>{badge}</div>
          <div className={classes.header}>
            <h1 className={classes.heading}>{title}</h1>
            {subTitle ? <p className={classes.subHeading}>{subTitle}</p> : null}
          </div>
          {date ? (
            <>
              <p className={classes.dateText}>🗓️ {date}</p>
              <Timer date={date} />
            </>
          ) : schedule ? (
            <ul className={classes.list}>
              {schedule.map((date) => (
                <li key={date.id} className={classes.dateText}>
                  🗓️ {date.text}
                </li>
              ))}
            </ul>
          ) : null}
          <CtaButton withGlow={false} href={link.link}>
            {link.text}
          </CtaButton>
          {bottomText ? (
            <p className={classes.smallText}>{bottomText}</p>
          ) : null}
        </div>
        <div className={classes.imageBox}>
          <img
            src={`${process.env.REACT_APP_CMS_DOMAIN}${image.url}`}
            alt={title}
          />
        </div>
      </div>
    </section>
  );
}
