import { Link } from "react-router-dom";
import classes from "./index.module.scss";
import { Box, Typography } from "@mui/material";
import { formatDate, getTagNames } from "./helpers";
import { ReactComponent as CircleIcon } from "./images/circle.svg";
import { ReactComponent as CaldendarIcon } from "./images/calendar.svg";

export default function PostCard({ data, variant = "post", link = "" }) {
  const eventVariant = variant === "event";

  return (
    <Box className={classes.card}>
      <Box component={Link} className={classes.image} to={link}>
        <img
          src={`${process.env.REACT_APP_CMS_DOMAIN}${data.image.url}`}
          width={data.image.formats.thumbnail.width}
          alt={data.title}
        />
      </Box>
      <Typography className={classes.title} component={Link} to={link}>
        <span>{data.title}</span>
      </Typography>
      {data.date ? (
        <Box className={classes.eventDateBox}>
          <CaldendarIcon />
          <Typography className={classes.eventDate}>
            {eventVariant ? data.date : formatDate(data.date)}
          </Typography>
        </Box>
      ) : data.schedule ? (
        <div>
          {data.schedule.map((date) => (
          <Box key={date.id} className={classes.eventDateBox}>
            <CaldendarIcon />
            <Typography className={classes.eventDate}>
              {eventVariant ? date.text : formatDate(date.text)}
            </Typography>
          </Box>
          ))}
        </div>
      ) : null}
      <Typography className={classes.date}>
        {getTagNames(data.tags, <CircleIcon />)}
      </Typography>
      <Box className={classes.excerpt}>
        {eventVariant ? data.shortDescription : data.description}
      </Box>
    </Box>
  );
}
