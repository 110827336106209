import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import classes from './index.module.scss';

export default function Card({ post }) {
  return (
    <Box className={classes.card}>
      <Box
        component={Link}
        to={`/posts/${post.slug}`}
        className={classes.card__image}
      >
        <img
          src={`${process.env.REACT_APP_CMS_DOMAIN}${post.image.url}`}
          width={post.image.formats.thumbnail.width}
          alt={post.title}
        />
      </Box>
      <Box className={classes.card__text}>
        <Typography
          className={classes.card__title}
          component={Link}
          to={`/posts/${post.slug}`}
        >
          <span>{post.title}</span>
        </Typography>
        <Typography className={classes.card__date}>
          {new Date(post.date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
          {post.tags.length ? (
            <>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='4'
                height='5'
                viewBox='0 0 4 5'
                fill='none'
              >
                <circle
                  cx='2'
                  cy='2.5'
                  r='2'
                  fill='#141416'
                  fillOpacity='0.4'
                />
              </svg>
              {post.tags
                .reduce((acc, { name }) => [...acc, name], [])
                .join(', ')}
            </>
          ) : null}
        </Typography>
      </Box>
    </Box>
  );
}
