import classes from "./Meet.module.scss";
import Heading from "../../Heading/Heading";
import ImageClip from "../../ImageClip/ImageClip";

export default function Meet(props) {
  if (!props || !props.id) return null;

  const { title, subTitle, richText, image } = props || {};
 return (
    <section className={classes.section}>
      <div className={classes.container}>
        <div className={classes.info}>
          <Heading>{title}</Heading>
          {subTitle ? <p className={classes.subHeading}>{subTitle}</p> : null}
          <div
            className={classes.richText}
            dangerouslySetInnerHTML={{ __html: richText }}
          />
        </div>
        <div className={classes.imageBox}>
          <ImageClip
            imgUrl={`${process.env.REACT_APP_CMS_DOMAIN}${image.url}`}
          />
        </div>
      </div>
    </section>
  );
}
