import { Box } from "@mui/material";
import classes from "./index.module.scss";

export default function Categories({ category, categoriesList, setCategory }) {
  return (
    <Box className={classes.navigation}>
      <button
        onClick={() => setCategory("")}
        className={`${classes.link} ${!category && classes.active}`}
      >
        All Events
      </button>
      {categoriesList && categoriesList.length > 0
        ? [...categoriesList].reverse().map((item) => (
            <button
              key={item.id}
              onClick={() => setCategory(`${item.id}`)}
              className={`${classes.link} ${
                category === `${item.id}` && classes.active
              }`}
            >
              {item.name}
            </button>
          ))
        : null}
    </Box>
  );
}
