export const getRelatedPostsQuery = async ({ tags, postDetailsSlug }) => {
  const tagIds = tags?.map((tag) => tag.id);

  if (!tagIds || tagIds.length === 0) {
    return [];
  }

  return {
    filters: {
      tags: {
        id: {
          $in: tagIds,
        },
      },
      slug: {
        $ne: postDetailsSlug,
      },
    },
    populate: {
      image: true,
      tags: {
        populate: '*',
      },
    },
  };
};
