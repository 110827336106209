import { useCallback, useEffect, useState } from 'react';

export default function usePopup() {
  const [open, setOpen] = useState(false)

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  

  useEffect(() => {
    return () => handleClose();
  }, [handleClose]);

  return { open, handleOpen, handleClose };
}
